<template>
  <div class="text-uppercase text-grey-8 text-right text-weight-bolder">
    passo: {{ step }}/{{ totalSteps }}
  </div>
</template>

<script>
export default {
  name: "SignupCounter",

  props: {
    step: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
  },
};
</script>
