<template>
  <div class="text-grey-7">
    <p class="text-sign-up-footer">
      <q-checkbox v-model="accept_terms" />
      {{ $t("login.signUp.when_create_u_agree") }}
      <a href="#" @click.prevent="dialog = true">{{
        $t("login.signUp.client_services_terms")
      }}</a>
    </p>
    <p v-if="!accept_terms" class="text-center text-red">
      Aceite os termos de uso.
    </p>
  </div>

  <q-dialog
    v-model="dialog"
    persistent
    :maximized="true"
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-card class="bg-white text-dark o">
      <q-bar class="bg-dark">
        <q-space />
        <q-btn color="white" dense flat icon="close" v-close-popup>
          <q-tooltip class="bg-black text-white">{{
            $t("global.close")
          }}</q-tooltip>
        </q-btn>
      </q-bar>
      <div class="row justify-center">
        <div class="terms-content scroll">
          <quill-editor
            :value="docContent.content"
            :disabled="state.disabled"
            :options="state.editorOption"
          />
        </div>
      </div>
      <q-card-actions align="center">
        <q-btn flat :label="$t('global.close')" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import quillEditor from "@/modules/main/components/quillEditor";
import { ref, reactive, defineProps } from "vue";

defineProps({
  docContent: {
    type: Object,
    required: true,
  },
});

const accept_terms = ref(true);
const dialog = ref(false);

const state = reactive({
  editorOption: {
    readOnly: true,
    theme: "snow",
    modules: {
      toolbar: false,
    },
  },
  disabled: true,
});
</script>

<style lang="scss" scoped>
.text-sign-up-footer {
  a {
    text-decoration: none;
    color: #222222;
    font-weight: 600;
  }
}
.terms-content {
  max-height: 90vh;
  max-width: 900px;
}
</style>
