<template>
  <q-intersection transition="slide-left">
    <Form
      class="column justify-between full-height q-mt-lg"
      :initial-values="initialValues"
      :validation-schema="schema"
      @submit="onSubmit"
    >
      <Field
        v-for="(structure, key) in structureForm"
        :key="key"
        :name="structure.name"
        v-slot="{ errorMessage, value, field }"
      >
        <div
          class="bg-yellow-3 q-pa-md default-rounded q-mb-sm"
          v-if="isEmailValidated == false && structure.name === 'email'"
        >
          Por favor digite seu e-mail corretamente, enviaremos um código para
          validação.
        </div>
        <q-select
          v-if="structure.type === 'select'"
          color="dark"
          v-bind="field"
          :type="structure.type"
          :counter="!!structure.maxlength"
          :maxlength="structure.maxlength"
          :model-value="value"
          :label="structure.label"
          :error-message="errorMessage"
          :error="!!errorMessage"
          :options="structure.options"
          option-label="singular_name"
        >
        </q-select>
        <q-input
          v-else
          filled
          color="dark"
          class="q-mb-md"
          bottom-slots
          :stack-label="structure['stack-label']"
          v-bind="field"
          :debounce="1000"
          :type="structure.type"
          :mask="structure.mask"
          :counter="
            structure.name === 'email' ? undefined : !!structure.maxlength
          "
          :maxlength="structure.maxlength"
          :model-value="value"
          :label="structure.label"
          :error-message="errorMessage"
          :error="onErrorFields(structure.name, errorMessage)"
          @updated="setFields(value)"
          :success="
            (structure.name === 'email' && isEmailValidated) || undefined
          "
        >
          <template v-slot:append>
            <q-icon
              v-if="structure.name === 'password'"
              :name="
                structure.type === 'password' ? 'visibility_off' : 'visibility'
              "
              class="cursor-pointer"
              @click="togglePasswordVisibility('password')"
            />
            <q-icon
              v-if="structure.name === 'passwordConfirmation'"
              :name="
                structure.type === 'password' ? 'visibility_off' : 'visibility'
              "
              class="cursor-pointer"
              @click="togglePasswordVisibility('passwordConfirmation')"
            />
            <q-icon
              v-if="structure.name === 'store_name'"
              name="help"
              class="cursor-pointer"
              @click="alert = true"
            />
            <template v-if="structure.name === 'email'">
              <q-btn
                dense
                round
                icon="check"
                color="green"
                text-color="white"
                v-if="isEmailValidated == true && verifyHasEmailValidation()"
              />
            </template>
          </template>
        </q-input>

        <!-- Botão para abrir modal de validação de email -->
        <q-btn
          v-if="
            structure.name === 'email' &&
            errorMessage == undefined &&
            typeof value == 'string' &&
            isEmailValidated == false &&
            verifyHasEmailValidation()
          "
          icon="email"
          color="white"
          class="q-mb-md bg-red"
          style="margin-top: -20px"
          label="Clique para validar o e-mail"
          @click="openValidateEmail(value)"
          :loading="in_request_email_validate"
        />
      </Field>

      <q-checkbox
        v-model="opt_in"
        class="text-grey-8"
        :label="$t('global.opt_in_terms')"
      />

      <UseTermsComponent :docContent="docContent" />

      <div class="row">
        <div class="col-12">
          <q-btn
            outline
            color="white"
            type="submit"
            :loading="in_request"
            :disable="in_request || !isEmailValidated || !accept_terms"
            class="width-100 bg-grey-10"
            :label="$t('global.register')"
          />
        </div>
      </div>
    </Form>

    <ValidateEmailExt
      v-model="validateEmail"
      :email="emailField"
      :hash="emailHash"
      @resend="openValidateEmail('', true)"
      @verify="verified()"
    />
  </q-intersection>
</template>

<script>
import ValidateEmailExt from "@/modules/main/pages/account/children/verification-components/ValidateEmailExt.vue";
import UseTermsComponent from "./UseTermsComponent.vue";
import { getStructure, build, getMappedFields } from "@/shared/form-presets";
import { formatTimestamp } from "@/shared/helpers/numbersManipulation";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { Field, Form } from "vee-validate";
import { ref, reactive } from "vue";

const accountNamespace = createNamespacedHelpers("account");
const authNamespace = createNamespacedHelpers("auth");

export default {
  name: "formStepThree",

  emits: ["finished", "return"],

  props: {
    typePerson: {
      require: true,
      type: Array,
    },
    slugReadyOnly: {
      required: true,
      type: Boolean,
    },
    sign_up_skeleton: {
      required: false,
      type: Object,
    },
    docContent: {
      required: false,
      type: Object,
    },
  },

  components: {
    Field,
    Form,
    ValidateEmailExt,
    UseTermsComponent,
  },

  setup(props, root) {
    const in_request = ref(false),
      in_request_email_validate = ref(false),
      opt_in = ref(true),
      accept_terms = ref(true),
      emailField = ref(""),
      emailHash = ref(""),
      validateEmail = ref(false),
      isEmailValidated = ref(false);

    const { fetchSendChallengeExt, fetchResendChallengeExt } =
        accountNamespace.useActions([
          "fetchSendChallengeExt",
          "fetchResendChallengeExt",
        ]),
      { challengeCodes, getSignUpConfig } = authNamespace.useGetters({
        challengeCodes: "getSignUpSkeletonChallengeCodes",
        getSignUpConfig: "getSignUpConfig",
      });

    /**
     * Quando a empresa não tem regra de validação de email, liberar o cadastro
     */
    const verifyHasEmailValidation = () => {
      const existEmailLink = challengeCodes.value.find(
        (e) => e.channel == "email"
      );

      if (existEmailLink) return existEmailLink;
      else {
        isEmailValidated.value = true;
        return false;
      }
    };

    const fieldsDefinitions = ["email", "password", "passwordConfirmation"];

    const structureForm = reactive(getStructure(fieldsDefinitions));

    if (structureForm.type_as)
      structureForm.type_as.options = [...props.typePerson];

    let allFields = getMappedFields(fieldsDefinitions);

    const schema = build(allFields);
    const localInitialValues = localStorage.getItem("signUpStepThree");

    let initialValues = localInitialValues
      ? JSON.parse(localInitialValues)
      : {};

    const onSubmit = (data) => {
      in_request.value = true;
      data.opt_in = opt_in.value == true ? 1 : 0;
      localStorage.setItem("signUpStepThree", JSON.stringify(data));

      root.emit("finished", {
        data: {
          ...data,
          email_validated_at: verifyHasEmailValidation()
            ? formatTimestamp(new Date())
            : false,
        },

        goTo: "subscribe",
      });
    };

    const returnForm = () => root.emit("return");

    const openValidateEmail = (value, resent = false) => {
      emailHash.value = verifyHasEmailValidation().hash;

      in_request_email_validate.value = true;
      emailField.value = emailField.value ? emailField.value : value;

      if (resent) {
        fetchResendChallengeExt({
          value,
          type: "email",
          hash: emailHash.value,
        }).finally(() => (in_request_email_validate.value = false));
      } else {
        fetchSendChallengeExt({
          value,
          type: "email",
          hash: emailHash.value,
        })
          .then(() => (validateEmail.value = true))
          .finally(() => (in_request_email_validate.value = false));
      }
    };

    const onErrorFields = (field, errorMessage) => {
      if (field == "email" && errorMessage) isEmailValidated.value = false;
      return !!errorMessage;
    };

    return {
      opt_in,
      schema,
      emailHash,
      in_request,
      accept_terms,
      emailField,
      validateEmail,
      initialValues,
      structureForm,
      challengeCodes,
      isEmailValidated,
      getSignUpConfig,
      in_request_email_validate,
      onSubmit,
      returnForm,
      onErrorFields,
      openValidateEmail,
      verifyHasEmailValidation,
      verified: () => {
        isEmailValidated.value = true;
        validateEmail.value = false;
      },
      togglePasswordVisibility: function (field) {
        if (structureForm[field].type === "text")
          structureForm[field].type = "password";
        else structureForm[field].type = "text";
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.text-sign-up-footer {
  a {
    text-decoration: none;
    color: #222222;
    font-weight: 600;
  }
}
.terms-content {
  max-height: 90vh;
  max-width: 900px;
}
</style>
